<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    >
    </loading>
    <h4>Pagos pendientes</h4>
    <p>
      El cliente
      <strong>{{ this.payer != null ? this.payer.clientName : "" }}</strong>
      tiene pedidos pendientes de pago
    </p>
    
    <!-- creditsToApply: {{creditsToApply}}
    <br> -->
    <!-- creditsApplied: {{creditsApplied}} -->
    <b-row v-if="alert">
      <b-col cols="12">
        <div 
          :class="alertClass" 
          role="alert" 
          v-for="alert_message in alertMsgs" v-bind:key="alert_message">
            {{alert_message}}
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="7">
        <CCard class="mb-0" style="margin-top:0.5rem;">
          <CButton
            block
            color="link"
            class="text-left shadow-none card-header"
            @click="accordion = accordion === 0 ? false : 0"
          >
            <h5 class="m-0">Detalle de pendientes</h5>
          </CButton>
          <CCollapse :show="accordion === 0">
            <CCardBody>
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                stacked="md"
                show-empty
                small
                @filtered="onFiltered"
              >
                <template #cell(name)="row">
                  {{ row.value }}
                </template>

                <template #cell(isTotalPayment)="row">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      v-model="row.item.isTotalPayment"
                      @change="
                        updatePaymentType(row.item, row.index, $event.target)
                      "
                    />
                  </div>
                </template>

                <template #cell(parcialPayment)="row">
                  <b-form-group>
                    <b-form-input
                      type="number"
                      id="parcialPayment"
                      placeholder="Ingresa valor"
                      v-model.trim="row.item.parcialPayment"
                      min="0"
                      :disabled="row.item.isTotalPayment == true ? true : false"
                    >
                    </b-form-input>
                  </b-form-group>
                </template>
                <template #cell(createdAt)="row">
                  {{row.item.createdAt | formatDateTime4}}
                </template>
              </b-table>

              <b-row v-if="!areItemsOk">
                <b-col style="color: red">                  
                  Nota: los pagos parciales no pueden ser CERO, NEGATIVOS, VACÍO ó mayor al monto DEBE.
                </b-col>
              </b-row>

             
            </CCardBody>
          </CCollapse>
        </CCard>

        <client-credit/>
      </b-col>

      <b-col lg="5">
        <CCard class="mb-0" style="margin-top:0.5rem;">
          <CButton
            block
            color="link"
            class="text-left shadow-none card-header"
            @click="accordionResumen = accordionResumen === 0 ? false : 0"
          >
            <h5 class="m-0">Datos de pago</h5>
          </CButton>
          <CCollapse :show="accordionResumen === 0">
            <CCardBody>
              <b-row>
                <b-col lg="12" >
                  <b-form-group style="text-align: center">
                    <img 
                      :src="payment.url ? payment.url : 'https://firebasestorage.googleapis.com/v0/b/test-goguau.appspot.com/o/general%2Finvoice.jpg?alt=media&token=926c0acc-b1bd-48a1-a5eb-3be3beada190'"
                      alt="" 
                      class="invoice-img">       
                    <b-form-file
                      v-model.trim="$v.payment.img.$model"
                      placeholder="Cargar img de recibo .jpg, .png, .jpeg"
                      drop-placeholder="Coloca tu foto aquí..."
                      accept=".jpg, .png, .jpeg"
                      browse-text="Buscar"
                      @input="updateURL"
                      :class="{                        
                          'form-control is-invalid':$v.payment.img.$error,
                          'form-control is-valid': !$v.payment.img.$invalid
                        }">
                    </b-form-file>  
                    <b-form-invalid-feedback v-if="!$v.payment.img.required">
                          La img del recibo es obligatoria
                    </b-form-invalid-feedback>  
                  </b-form-group>                                            
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col lg="6">
                  Fecha de pago *
                  <b-form-group>                    
                     <b-form-datepicker 
                      id="example-datepicker" 
                      v-model="payment.date"                       
                      :class="{                        
                        'form-control is-invalid':$v.payment.date.$error,
                        'form-control is-valid': !$v.payment.date.$invalid
                      }"
                      >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                

                <!-- payment method -->
                <b-col lg="6">
                  Método de pago *
                  <b-form-group>
                    <b-form-select
                      id="method"
                      :plain="true"
                      :options="methodOptions"
                      v-model.trim="$v.payment.methodSelected.$model"
                      :class="{
                        'form-control is-invalid': $v.payment.methodSelected.$error,
                        'form-control is-valid': !$v.payment.methodSelected.$invalid
                      }"
                    >
                      <b-form-invalid-feedback
                        v-if="!$v.payment.methodSelected.required"
                      >
                        El método de pago es obligatorio.
                      </b-form-invalid-feedback>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <!-- Total payment -->
                <b-col lg="6">
                  <b-form-group>
                    Total a pagar ($) 
                    <b-form-input
                      id="amount"
                      type="number"
                      min="0"
                      placeholder="Ingresa valor"
                      :disabled="true"
                      v-model="totalPayment"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <!-- Amount received -->
                <b-col lg="6">
                  <b-form-group>
                    Monto recibido ($) *
                    <b-form-input
                      id="amount"
                      type="number"
                      min="0"
                      placeholder="Ingresa valor"
                      v-model.trim="$v.payment.amountReceived.$model"
                      :class="{
                          'form-control is-invalid': $v.payment.amountReceived.$error,
                          'form-control is-valid': !$v.payment.amountReceived.$invalid
                        }">
                    >
                    </b-form-input>
                     <b-form-invalid-feedback v-if="!$v.payment.transactionId.required">
                      El monto recibido es obligatorio.
                    </b-form-invalid-feedback>  
                  </b-form-group>
                </b-col>

                <!-- Transaction id -->
                <b-col lg="6">
                  <b-form-group> 
                    Id de transacción *                                                    
                    <b-form-input
                        type="text"
                        v-model.trim="$v.payment.transactionId.$model"
                        :class="{
                          'form-control is-invalid': $v.payment.transactionId.$error,
                          'form-control is-valid': !$v.payment.transactionId.$invalid
                        }">
                    </b-form-input>
                    <b-form-invalid-feedback v-if="!$v.payment.transactionId.required">
                      El id de transacción es obligatorio.
                    </b-form-invalid-feedback>  
                  </b-form-group>
                </b-col>
            
                <b-col lg="6">
                  <b-form-group>
                    Crédito a favor($)
                    <b-form-input
                      id="amount"
                      type="number"
                      min="0"
                      placeholder="Ingresa valor"
                      :disabled="true"
                      v-model="credit"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="alert">
                <b-col cols="12">
                  <div 
                    :class="alertClass" 
                    role="alert" 
                    v-for="alert_message in alertMsgs" v-bind:key="alert_message">
                      {{alert_message}}
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <b-button 
                    @click="submit"
                    :disabled="disableBtn">
                    Crear pago
                  </b-button>
                </b-col>
              </b-row>
            </CCardBody>
          </CCollapse>
        </CCard>
      </b-col>
    </b-row>

    <!-- Modal confirmation -->
    <section>
      <b-modal
        title="Resultado de operación"
        v-model="showModal"
        no-close-on-backdrop
        hide-header-close
        size="md"
      >
        <b-row>
          <b-col>
            <div v-for="msg in alertMsgs" v-bind:key="msg">
              {{msg}}
            </div>
          </b-col>
        </b-row>
        <template #modal-footer>
          <div class="w-100">
            <b-row>           
              <b-col lg="6">
                <b-button 
                  size="md" 
                  variant="warning" 
                  @click="goTo('payments')"
                  >
                  Aceptar
                </b-button>
              </b-col>
            </b-row>
          </div>
        </template>
      </b-modal>
    </section>

  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import {required} from 'vuelidate/lib/validators';
import moment from "moment";
import firebase from "firebase";
import ClientCredit from "./payment_info/ClientCredit.vue";
export default {
  name: "PendingPayments",
  components: {Loading, ClientCredit},
  data() {
    return {
      loading: false,
      error: false,
      alert: false,
      alertMsgs: [],
      showModal: false,
      disableSubmitBtn: false,
      accordion: 0,
      accordionResumen: 0,
      payer: null,
      items: [],
      fields: [
        { key: "id", label: "id pedido" },
        { key: "createdAt", label: "Fecha pedido" },
        {
          key: "petName",
          label: "Mascota",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "state",
          label: "Estado",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "article",
          label: "Articulo",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "quantity",
          label: "Cantidad",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "servicesLeft",
          label: "Ser. restantes",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "totalPrice",
          label: "Precio",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "amountPaid",
          label: "Pagado",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "owes",
          label: "Debe",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "isTotalPayment",
          label: "Pago total",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "parcialPayment",
          label: "Pago parcial",
          sortable: true,
          sortDirection: "desc"
        }
      ],
      methodOptions: ["Efectivo", "Bancolombia", "Daviplata", "Nequi"],
      totalRows: 1,
      currentPage: 1,
      perPage: 150,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      payment: {
        transactionId: null,
        amountReceived: null,
        date: new Date(),
        img: null,
        methodSelected: "Bancolombia",
        collectionFee: 0,
      },
      creditsToApply: [],
      creditsApplied: [],
      amountDiscount: 0,
    };
  },
  validations: {
    payment: {
      transactionId: { required },
      amountReceived: { required },
      date: { required },
      img: { required },
      methodSelected: { required }      
    },    
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    totalPayment() {
      let pendings = this.items;
      let totalPayment = 0;
      // let totalDiscount = this.getTotalDiscount()

      pendings.map(pendingItem => {
        if (pendingItem.isTotalPayment) {
          totalPayment += +pendingItem.owes;
        } else {
          totalPayment += +pendingItem.parcialPayment;
        }
      });

      if(totalPayment > 0 && this.creditsToApply.length > 0)
        totalPayment = this.getTotalPriceWithCreditRedeem(totalPayment)
      
      return this.payment.methodSelected == 'Efectivo' ? totalPayment + 400 : totalPayment 
    },
    credit() {
      if (this.payment.amountReceived == 0 || this.payment.amountReceived < this.totalPayment) {
        return 0;
      }
      return this.payment.amountReceived - this.totalPayment;
    },
    alertClass() {
      if(!this.error) return "alert alert-danger"
      return "alert alert-success"
    },
    areItemsOk(){
      
      for(let item of this.items){
        if(!item.isTotalPayment){
          if(+item.parcialPayment <= 0 || 

             +item.parcialPayment > item.owes
          ) return false
          
        }
      }
      return true
    },
    disableBtn(){
      if(this.disableSubmitBtn || !this.areItemsOk) return true
      return false
    }
 
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.items = this.$store.getters.pendingPayments;
  },
  created() {
    this.payer = this.$store.getters.paymentBy;
    this.loading = true;
    this.watch()
    this.fetchClientCredits();
  },
  methods: {
    watch(){
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'defClientCreditsToApply') {
          this.creditsToApply = this.$store.getters.clientCreditsToApply
        }
      })
    },
    fetchClientCredits() {
      let clientId = this.payer.client.id
      let getClientCredits = this.$firebase.functions().httpsCallable('getClientCredits')

      getClientCredits({clientId}).then((response) => {
        let {data: credits, success} = response.data
        console.log(response.data)
        if (success && credits.length > 0) {
          credits.map(credit => credit.aplicar = false)
          this.$store.commit('defClientCredits', credits);
        }
      }).catch((error) => {
         console.log(error);
      }).finally(() => this.loading = false);
    },
    getTotalPriceWithCreditRedeem(totalPrice){
      this.amountDiscount = 0

      this.cleanCreditsApplied()
      this.creditsToApply.map(credit => {
        let item = {...credit}
        if(totalPrice > 0) {
          if(item.valor_disponible <= totalPrice) {
            this.amountDiscount += item.valor_disponible
            item.redimido = true
            item.valor_redimido += item.valor_disponible
            item.valor_disponible = 0
          } else {
            item.valor_disponible -= totalPrice
            item.valor_redimido += totalPrice
            this.amountDiscount += totalPrice
          }
          delete item.aplicar
        }
        this.creditsApplied.push(item)
      });
      return totalPrice - this.amountDiscount
    },
    cleanCreditsApplied(){
      this.creditsApplied = []
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid ) {                    
        this.alert = true
        this.alertMsgs = ['No has completado correctamente tus datos']
        this.scrollToTop()   
        return              
      } 
      if(this.payment.amountReceived < this.totalPayment) {
        this.alert = true
        this.alertMsgs = ['El pago recibido debe ser igual o mayor al total a pagar']
      }
      else {
        this.alert = false
        this.alertMsgs = []
        this.loading = true
        this.createPayment()
      }
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    updatePaymentType(item, index) {
      if (item.isTotalPayment) item.parcialPayment = 0;
    },
    setPartialPayments(item) {},
    updateURL(){                        
      let url = URL.createObjectURL(this.payment.img)
      this.payment['url'] = url
      url = null
      
    },  
    async createPayment() {
      try {
        this.payment.url  = await this.postInvoceImage()

        if(this.payment.methodSelected == 'Efectivo')
          this.payment.collectionFee = 400
        

        const payload = Object.assign({}, this.payment)
        payload['date'] = payload.date.toISOString()
        payload['credit'] = this.credit
        payload['creditDiscount'] = this.amountDiscount
        payload['totalPayment'] = this.totalPayment + this.amountDiscount
        delete payload.img
        payload.amountReceived = +payload.amountReceived
        payload.pendingOrders = this.items
        payload.clientId = this.payer.client.id
        payload.petId = this.payer.id
        payload.creditsApplied = this.creditsApplied 

        this.alertMsgs = [];
        this.alert = false;
        let clientDebtsPayment = this.$firebase.functions().httpsCallable("clientDebtsPayment")
        clientDebtsPayment(payload).then((response) => {
          const data = response.data;
          this.showModal = true
          this.alertMsgs.push('Pago de deuda registrado exitosamente')
        }).catch(error =>{
          console.log(error);
          this.alert = true
          this.alertMsgs.push('ERROR: registrando el pago de la deuda')
        }).finally(_=>{
          this.loading = false;
          this.disableSubmitBtn = true;          

        })

      } catch (error) {
        alert('No se pudo crear el pago')
      }
     
    },
    async postInvoceImage() {
      
      let {year, month, day} = this.getPaymentDates(this.payment.date)

      const newFileName = `${this.payer.client.id}-${month}${day}-${this.payment.transactionId}`
      const file = this.payment.img;

      return this.createInvoiceImg(year, month, newFileName, file)
    },
    getPaymentDates(date) {
      let dates = {
        year: moment(date).format('YYYY'),
        month: moment(date).format('MMMM'),
        day: moment(date).format('DD'),
      };
      return dates
    },
    createInvoiceImg(year,month, fileName, file){
      return new Promise((resolve, reject) => {
        let fileTask = firebase.storage().ref(`invoices/${year}/${month}/${fileName}`).put(file)
        fileTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        } , function(error) {
          reject(error)
        }, async function() {
          let downloadURL = await fileTask.snapshot.ref.getDownloadURL()
            // Upload completed successfully, now we can get the download URL
          resolve(downloadURL)
        });
      })
    },
    goTo(to) {
      if(to == 'payments') {
        this.$router.replace({ path: '/financial/create_payment' })
      }
    },  
  
  },
 
};
</script>

<style lang="scss" scoped>

</style>

